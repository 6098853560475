import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "quotation-faq-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_title, {
        level: 3,
        class: "text-center mb-6"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("FAQs")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_collapse, {
        "active-key": _ctx.activeFaqKeys,
        "onUpdate:active-key": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeFaqKeys) = $event)),
        class: "quotation-faq-card__collapse"
      }, {
        expandIcon: _withCtx(({ isActive }) => [
          _createVNode(_component_AppIcon, {
            name: "chevron",
            class: _normalizeClass(["transform duration-300 w-4 h-3 mr-6", [isActive && 'rotate-180']])
          }, null, 8, ["class"])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mockFrequentlyAskedQuestions, (faq, index) => {
            return (_openBlock(), _createBlock(_component_a_collapse_panel, {
              key: index,
              class: "quotation-faq-card__collapse-item"
            }, {
              header: _withCtx(() => [
                _createVNode(_component_a_typography_title, {
                  level: 5,
                  class: "quotation-faq-card__collapse-item__question"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(faq.question), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_typography_paragraph, { class: "m-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(faq.answer), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["active-key"])
    ]),
    _: 1
  }))
}