
import Card from "@/shared/components/Card.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const activeFaqKeys = ref([]);

    // FIXME: Replace contents
    const mockFrequentlyAskedQuestions: { question: string; answer: string }[] =
      [
        {
          question: t("Question"),
          answer:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas aliquam ex suscipit.",
        },
        {
          question: t("Question"),
          answer:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas aliquam ex suscipit.",
        },
        {
          question: t("Question"),
          answer:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas aliquam ex suscipit.",
        },
        {
          question: t("Question"),
          answer:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas aliquam ex suscipit.",
        },
      ];

    return { t, activeFaqKeys, mockFrequentlyAskedQuestions };
  },
  components: { Card, AppIcon },
});
